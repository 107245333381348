<template>
	<div>
		<tree :arr="tree" @nodeClick="outClick" :isHave="isHave" :isOrder="isOrder" :courseId="courseId" :courseName="courseName" :sectionId="sectionId" />
	</div>
</template>

<script>
import tree from './tree.vue'
export default {
	// 可用组件的哈希表
	components: {tree},
	// 接收传值
	props: {
		tree: {
			type: Array,
			default: ()=>[]
		},
    // // 是否购买
    // isBuy: {
    //   type: Boolean,
		// 	default: false
    // },
		// 正在播放视频id
		sectionId: {
			type: Number,
			default: null
    },
    // 课程是否购买
		isHave: {
			type: Number,
			default: null
    },
    // 课程id
		courseId: {
			type: Number,
			default: null
    },
    // 课程课程名称
		courseName: {
			type: String,
			default: null
		},
		// 课程顺序播放
    isOrder: {
      type: Boolean,
      default: true,
    },
	},
	// 数据对象
	data () {
		return {}
	},
	// 事件处理器
	methods: {
		outClick(id){
			this.$emit('nodeClick', id)
		}
	},
	// 生命周期-实例创建完成后调用
	created () { },
	// 生命周期-实例挂载后调用
	mounted () { },
	// 生命周期-实例销毁离开后调用
	destroyed () { },
	// 计算属性监听
	computed: {},
	// 自定义的侦听器
	watch: {}
}
</script>

<style lang="less" scoped></style>
