<template>
  <div>
    <!-- 进度和功能 -->
    <div class="all-side-r">
      <!-- 进度 -->
      <div class="progress" :class="{'orderProgress' : !isOrder}" v-if="obj.fileId && isBuy == 1 && obj.lock == 0 && (obj.passStatus != 1 || obj.watchProgress != 0)">
        <span>进度</span>
        <a-progress :showInfo="false" strokeColor="#15B7DD" trailColor="#EBEBEB" :percent="obj.watchProgress ? (obj.watchProgress * 100) : 0" size="small" />
        <span>{{obj.watchProgress ? ((obj.watchProgress * 100).toFixed(0)) : 0 }}%</span>
      </div>
      <!-- 名称和时长 -->
      <div class="nameTime" v-if="obj.fileId">
        <div class="name">
          <span v-for="(name,index) in obj.teacherList" :key="index">{{name}} </span>
        </div>
        <span class="time">时长：{{obj.duration == '-' ? '-' : formatSeconds(obj.duration)}}</span>
      </div>
      <!-- 操作 -->
      <div class="option" :class="{'noOrderOption' : !isOrder}" v-if="obj.fileId">
        <!-- 已购买 -->
        <template v-if="isBuy">
          <!-- 加锁 -->
          <img class="tree_icon" v-if="obj.lock==1 && isOrder" src="@/assets/image/selected/lock-999.png" />
          <!-- 自测 -->
          <div class="style-one" v-else-if="obj.isHaveSelfTest==1 && isOrder" @click.stop="onTest(obj)">
            <img class="tree_icon" style="margin-right:4px" src="@/assets/image/selected/test_icon1.png" />
            <span class="tree_txt">自测</span>
            <img class="tree_icon" v-show="obj.studyStatus==4" style="margin-left:4px" src="@/assets/image/selected/test_icon2.png" />
            <img @mouseover="showIcon = true" @mouseout="showIcon = false" class="tree_icon" v-show="obj.studyStatus==5" style="margin-left:4px" src="@/assets/image/selected/test_icon3.png" />
            <div class="tips" v-if="showIcon">当前小节自测未通过，请再次进行测试，通过后可解锁下一视频的学习</div>
            <div class="triangle" v-if="showIcon"></div>
          </div>
          <!-- 随机播放课程学习状态 -->
          <div class="style-one style-two" v-if="!isOrder">
            <div v-if="obj.passStatus == 3 || obj.passStatus == 4">
              <img class="tree_icon" src="@/assets/image/selected/20230330-161311.png" alt="">
              <span style="color:#83CE82">已学完</span>
            </div>
            <div v-if="obj.passStatus == 2 || (obj.watchProgress!=0 && obj.watchProgress!=1)">
              <img class="tree_icon" src="@/assets/image/selected/20230330-161322.png" alt="">
              <span style="color:#15B7DD">学习中</span>
            </div>
            <div v-if="obj.passStatus == 1 && obj.watchProgress == 0" >
              <img class="tree_icon" src="@/assets/image/selected/20230330-161317.png" alt="">
              <span style="color:#999999">待学习</span>
            </div>

            <!-- 自测按钮 -->
            <img class="tree_icon" v-if="obj.isHaveSelfTest==1" @click.stop="onTest(obj)" style="margin-left:50px" src="@/assets/image/selected/test_icon1.png" />
          </div>
        </template>

        <!-- 未购买 -->
        <template v-else>
          <!-- 试看 -->
          <span class="btn" v-if="obj.isFreeTrial==1" @click.stop="onFreeTrial()">免费试看</span>
          <!-- 加锁 -->
          <img class="tree_icon" v-else-if="obj.lock==1" src="@/assets/image/selected/lock-999.png" />
        </template>        
      </div>
    </div>
  </div>
</template>

<script>
import { formatSeconds } from '@/unit/fun.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    obj:{},
    // 是否购买
    isBuy: {
      type: null,
			default: null
    },
    // 课程id
		courseId: {
			type: Number,
			default: null
    },
    // 课程课程名称
		courseName: {
			type: String,
			default: null
    },
    // 课程顺序播放
    isOrder: {
      type: Boolean,
      default: true,
    },
  },
  // 数据对象
  data () {
    return {
      showIcon: false,
    }
  },
  // 事件处理器
  methods: {
    formatSeconds,
    onFreeTrial(){
      this.$emit('nodeClick')
      // this.$message.warning('暂不支持免费试看，请去App端试看视频');
    },
    onTest(e){
      // 如果从目录点击自测，必须过滤未看完的视频章节点击自测
      if(!(this.obj.watchProgress && (this.obj.watchProgress * 100).toFixed(0) == 100) && this.isOrder){
        this.$message.warning('请先看完本章节，再进行自测')
        return false
      }
      this.$router.push("/examination/selfTest?courseName=" + this.courseName+ "&sectionName="+ e.sectionName+ '&courseId=' + this.$AES.encode_data(String(this.courseId)) + '&sectionId=' + this.$AES.encode_data(String(e.sectionId)) + '&isNoOrder=' + (this.isOrder ? 0 : 1) );
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  .tips{
    position: absolute;
    top: -136px;
    right: -20px;
    padding: 14px 21px 13px 21px;
    width: 227px;
    height: 120px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 5px 5px;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    text-align: left;
  }
  .triangle {
    position: absolute;
    top: -17px;
    right: -6px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 13px solid #fff;
    filter: drop-shadow(2px 6px 2px rgba(0, 0, 0, .2));
  }

// 右侧进度和功能
  .all-side-r{
    display: flex;
    align-items: center;
    color: #333333;
    .progress{
      display: flex;
      align-items: center;
      width: 270px;
      margin-left: 30px;
      color:@theme;
      >span{
        display: inline-block;
        width: 60px;
      }
      /deep/ .ant-progress{
        margin: 0 15px;
      }
      /deep/ .ant-progress-inner{
        background-color: #EBEBEB;
      }
      /deep/.ant-progress-text{
        color: @theme;
      }
    }
    .orderProgress{
      width: 240px;
    }
    .nameTime{
      min-width: 240px;
      margin-left: 35px;
      overflow: hidden;
      .name{
        display: inline-block;
        width: 50%;
        text-align: center;
      }
      .time{
        float: right;
        // text-align: right;
        width: 41%;
      }
      @media screen and (max-width:16000px) {
        font-size: 14px;
        min-width: 200px;
      }
    }
    .option{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 500;
      color: @theme;
      line-height: 24px;
      margin-left: 45px;
      width: 88px;
      height: 29px;
      .style-one{
        display: flex;
        align-items: center;
        position: relative;
        @media screen and (max-width:16000px) {
          .tree_txt{
            font-size: 14px;
          }
        }
      }
      .style-two{
        .tree_icon{
          margin-right: 4px;
        }
      }



      @media screen and (max-width:16000px) {
        .tree_icon{
          width: 14px;
          height: 14px;
        }
      }
      .btn{
        display: inline-block;
        width: 88px;
        height: 29px;
        font-size: 14px;
        border-radius: 5px;
        line-height: 29px;
        color: #ffffff;
        background: @theme;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        &::before{
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: -100%;
          background: linear-gradient(90deg,transparent,hsla(0,0%,100%,.5),transparent);
          transition: all .5s;
        }
        &:hover:before {
          left: 100%;
        }
      }
    }
    .noOrderOption{
      width: 140px;
    }
  }
</style>
